/*
 * @Author: liujingfa 
 * @Date: 2020-02-24 11:07:18 
 * @Last Modified by: yinhongwei
 * @Last Modified time: 2022-04-28 14:44:54
 */
import Vue from 'vue';
// import qs from 'qs';
import cookie from 'gome-utils-cookie'
import http from 'gome-utils-http'
// import 'normalize.css/normalize.css';

import App from './comps/index.vue';
import store from './store';

// import VConsole from 'vconsole';
// let vConsole = new VConsole();
// import router from './router';

Vue.config.productionTip = false;

// Vue.prototype.$qs = qs;

// 注册全局事件总线
const Bus = new Vue();
function init() {
    const vm = new Vue({
        el: '#app',
        // router,
        store,
        Bus,
        render: h => h(App),
    });
    
    Vue.use({
        vm,
    });
}

let cookies = cookie.parse()
if (window.token && window.chat_userid) {
    init()
} else {
    window.chat_userid = Number(cookies['SSO_USER_ID'] || '0')
    http({
        url: `${SERVICE_Mobile_IP}/h5/mprofile/saveScn.jsp`,
        type: "post",
        data: {},
        cors: 'Y',
        isNeedLoading: 'Y'
    }).then(data => {
        if (data.isSuccess=='Y') {
            cookies = cookie.parse()
            window.token = decodeURIComponent(cookies['SCN'] || '')
            console.log('scn data====', window.token)
            // 如果还获取不到，就刷新页面
            if (!window.token && !document.referrer) {
                window.location.replace(window.location.href)
            }
            window.chat_userid = Number(cookies['SSO_USER_ID'] || '0')
            init()
        } else {
            let backUrl = window.location.href
            if (!!Buffer) {
                backUrl = Buffer.from(backUrl).toString('base64')
            }
            window.location.replace(window.WAP_LOGIN_IP + "/login.html?return_url=" + backUrl)
        }
    }).catch(err => {
        console.log('===saveScn===', err)
        init()
    })
}