<template>
  <ul class="gui-title-bar-wrapper">
    <li class="go-back" @click="handleGoBack">
      <span class="icon"></span>
    </li>
    <li class="title">{{ title }}</li>
    <li class="my-cart"></li>
  </ul>
</template>

<script>
export default {
  name: "GuiTitleBar",
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  methods: {
    handleGoBack() {
      window.history.go(-1);
    }
  }
};
</script>

<style lang="less" scoped>
.gui-title-bar-wrapper {
  width: 100%;
  height: 0.88rem;
  background-color: #fff;
  color: #262c32;
  font-size: 0.34rem;
  list-style: none;
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid rgba(209, 211, 211, 0.5);
  .go-back {
    width: 0.8rem;
    height: 0.8rem;
    margin: 0 0.12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      display: inline-block;
      width: 0.56rem;
      height: 0.56rem;
      background-image: url("./../images/icon_left_back_dark.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      background-origin: content-box;
      background-clip: content-box;
    }
  }
  .title {
    flex: 1;
    text-align: center;
  }
  .my-cart {
    width: 0.8rem;
    height: 0.8rem;
  }
}
</style>