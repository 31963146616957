import { render, staticRenderFns } from "./HistoryMessage.vue?vue&type=template&id=595cffba&scoped=true&"
import script from "./HistoryMessage.vue?vue&type=script&lang=js&"
export * from "./HistoryMessage.vue?vue&type=script&lang=js&"
import style0 from "./HistoryMessage.vue?vue&type=style&index=0&id=595cffba&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "595cffba",
  null
  
)

export default component.exports