/*
 * @Author: yinhongwei 
 * @Date: 2020-02-20 14:21:29 
 * @Last Modified by: yinhongwei
 * @Last Modified time: 2022-5-11 15:20:48
 */
<template>
  <div v-show="isShow" class="gui-templet-goto-top" @click="gotoTop">
    <img src="./../images/return-top.png">
  </div>
</template>
<script>
export default {
  name: "NavGotoTop",
  props: {
    dataInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isShow: false
    };
  },
  computed: {
    imgUrl() {
      return (this.dataInfo && this.dataInfo.imageUrl) || "";
    }
  },
  methods: {
    gotoTop() {
      if (!!window) {
        window.scrollTo(0, 0);
      }
    },
    scrollHandler(e) {
      let top =
        document.documentElement.scrollTop || document.body.scrollTop || 0;
      this.isShow = top > 150;
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.scrollHandler, false);
    });
  }
};
</script>
<style lang="less" scoped>
.gui-templet-goto-top {
  width: 0.8rem;
  height: 0.8rem;
  position: fixed;
  right: 0.3rem;
  bottom: 1.5rem;
  z-index: 888;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>