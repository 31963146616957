/*
 * @Author: liujingfa 
 * @Date: 2020-02-24 12:06:50 
 * @Last Modified by: yinhongwei
 * @Last Modified time: 2022-5-11 15:21:43
 */
<template>
  <section class="gui-guide-recommend-wrapper">
    <p class="recommend-title">全程导购</p>
    <div
      class="chat-list-item"
      v-for="(item, index) in sellersRecomList"
      :key="index"
      @click="handleSeeStaff(item)"
    >
      <div class="item-container">
        <div class="title-wrap">
          <img
            :src="item.imgUrl"
            onerror="this.src='data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='"
          >
        </div>
        <dl class="base-info">
          <dt class="left">
            <p class="info">
              <span
                :class="['tip', {'online-status': item.isOnline === 'Y', 'offline-status': item.isOnline === 'N'}]"
              ></span>
              <span class="nickname">{{ item.salesAssistantName }}</span>
            </p>
            <p class="info">
              <!-- <span class="score">{{ item.comprehensiveScore }}</span><span class="unit">分</span> -->
              <span class="flag">{{ item.grade }}</span>
            </p>
            <ul class="info">
              <li class="font22" :style="{marginLeft: '-0.08rem'}">
                <span>已服务 {{ item.numberOfPeopleServed }} 单</span>
              </li>
            </ul>
          </dt>
          <dd class="right">
            <a
              class="consult-entry"
              :href="`//chat.m.${calcDomain}.com.cn/chat.html?entry=shopping&orgi=${item.orgi}&shopId=${item.storeId}&staffId=${item.salesAssistantId}&sptype=message`"
              v-if="item.show"
            >
              <span class="icon-consult"></span>
              <span v-if="item.isOnline === 'Y'">咨询</span>
              <span v-else>留言</span>
            </a>
          </dd>
        </dl>
      </div>
      <ul class="good-brand-list" v-if="item.goodAtBrand">
        <!-- <li><span class="font22">所属品牌: </span><span class="brand font22" v-for="(brand, idx) in item.goodAtBrand" :key="idx">{{ brand }}</span></li> -->
        <li class="font22">
          <span>所属品牌：</span>
          <span class="brand">{{ item.goodAtBrand }}</span>
        </li>
      </ul>
      <ul class="sale-tags-list" v-if="item.goodAtCategory&&item.goodAtCategory.length>0">
        <li v-for="(category, idx) in item.goodAtCategory" :key="idx">
          <span>{{ category }}</span>
        </li>
      </ul>
      <!-- 没有品类时的占位 -->
      <ul class="sale-tags-list" v-else></ul>
    </div>
    <div class="template-load-failed-tip" v-if="sellersLoadFlag !== 1">
      <dl>
        <dt class="face-sad"></dt>
        <dd>{{ loadFailedTip }}</dd>
      </dl>
      <div class="btn-reload" v-if="sellersLoadFlag === -1" @click="handleReloadData">
        <span>重新加载</span>
      </div>
    </div>
    <!-- <div class="template-no-more">看完啦</div> -->
  </section>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("chatList");

export default {
  name: "GuideRecommend",
  props: {
    dataInfo: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState({
      sellersLoadFlag: state => state.sellersLoadFlag
    }),
    calcDomain() {
      const _host = location.host;
      const _Domain =
        _host.indexOf("gomeuat") > 0
          ? "gomeuat"
          : _host.indexOf("tslive") > 0
          ? "tslive"
          : "gome";
      return _Domain;
    },
    loadFailedTip() {
      let str = "亲，暂未能帮您找到导购员";
      if (this.sellersLoadFlag === -1) {
        str = "加载失败了哟～";
      } else if (!this.sellersLoadFlag && this.sellersRecomList.length === 0) {
        str = "亲，暂未能帮您找到导购员";
      }
      return str;
    },
    loadFailedImg() {
      let img = require("./../images/load-failed@2x.png");
      return img;
    },
    sellersRecomList() {
      const list = this.dataInfo;
      const defaultTitle = "//gfs12.gomein.net.cn/T1Q3WgByd_1RCvBVdK.jpg";
      let arr = [];
      list.map((item, index) => {
        let goodAtBrands = [...new Set(item.goodAtBrand)];
        goodAtBrands =
          goodAtBrands.length > 1 ? goodAtBrands.slice(0, 10) : goodAtBrands;
        let goodAtCategory = [...new Set(item.goodAtCategory)];
        goodAtCategory =
          goodAtCategory.length > 1
            ? goodAtCategory.slice(0, 6)
            : goodAtCategory;
        arr.push({
          salesAssistantId: item.salesAssistantId || "",
          salesAssistantName: item.salesAssistantName || "",
          grade: item.grade || "产品专家",
          imgUrl: item.imgUrl || defaultTitle, // 头像地址
          goodAtCategory: goodAtCategory,
          comprehensiveScore: item.comprehensiveScore,
          numberOfPeopleServed: item.numberOfPeopleServed || "--",
          goodAtBrand: goodAtBrands.join(" "),
          show: item.show, // 为0不显示客服按钮
          orgi: item.orgi || "",
          storeId: item.stId || "",
          isOnline: item.isOnline // 在线
        });
      });
      return arr;
    }
  },
  methods: {
    handleSeeStaff(obj) {
      const _host = location.host;
      const _Domain =
        _host.indexOf("gomeuat") > 0
          ? "gomeuat"
          : _host.indexOf("tslive") > 0
          ? "tslive"
          : "gome";
      window.location.href = `//item.m.${_Domain}.com.cn/staff_info.html?guideNo=${
        obj.salesAssistantId
      }&stid=${obj.storeId}`;
    },
    handleReloadData() {
      this.$emit("handleReload");
    }
  }
};
</script>

<style lang="less" scoped>
.gui-guide-recommend-wrapper {
  .recommend-title {
    height: 1rem;
    line-height: 1rem;
    font-size: 0.34rem;
    color: #333;
    background-color: #fff;
    font-family: "PingFangSC-Medium";
    padding-left: 0.32rem;
  }
  .chat-list-item {
    background-color: #fff;
    .item-container {
      display: flex;
      align-items: flex-start;
    }
    .title-wrap {
      width: 1.2rem;
      height: 1.2rem;
      margin: 0.2rem 0.24rem 0 0.32rem;
      img {
        width: inherit;
        height: auto;
        border-radius: 50%;
      }
    }
    .base-info {
      width: 100%;
      margin: 0.2rem 0.32rem 0 0;
      display: flex;
      justify-content: space-between;
      .left {
        .info {
          span {
            display: inline-block;
          }
          &:nth-child(1) {
            display: flex;
            align-items: center;
            .tip {
              width: 0.16rem;
              height: 0.16rem;
              margin-right: 0.14rem;
              // border: 4px solid rgba(255,255,255,0.80);
              border-radius: 50%;
            }
            .online-status {
              background-color: #09bb07;
            }
            .offline-status {
              background-color: #cacccf;
            }
            .nickname {
              font-family: "PingFangSC-Medium";
              font-size: 0.34rem;
              color: #202020;
              letter-spacing: -0.32px;
              line-height: 0.48rem;
            }
          }
          &:nth-child(2) {
            line-height: 0.4rem;
            display: flex;
            align-items: center;
            margin-top: 5px;
            .score {
              font-size: 0.28rem;
              color: #ff4800;
            }
            .unit {
              font-size: 0.24rem;
              color: #3f3f3f;
              transform: scale(0.92);
            }
            .flag {
              // width: 95px;
              height: 0.26rem;
              line-height: 0.26rem;
              color: #fff;
              font-size: 0.2rem;
              background-color: #e6b365;
              text-align: center;
              border-radius: 0.195rem;
              // margin-left: 9px;
              padding: 0 0.12rem;
            }
          }
          &:nth-child(3) {
            line-height: 0.28rem;
            display: flex;
            margin-top: 0.08rem;
            span {
              color: #919599;
            }
            .font22 {
              font-size: 0.24rem;
              transform: scale(0.92);
            }
          }
        }
      }
      .right {
        .consult-entry {
          width: 0.84rem;
          height: 0.84rem;
          background-color: #fff3e2;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .icon-consult {
            display: inline-block;
            width: 0.26rem;
            height: 0.25rem;
            background: url("./../images/icon_consult@2x.png") no-repeat center
              center / 100% content-box content-box;
          }
          span {
            &:nth-child(2) {
              font-size: 0.24rem;
              color: #c48d3a;
              transform: scale(0.85);
              // line-height: 28px;
              margin-top: 0.05rem;
            }
          }
        }
      }
    }
    .good-brand-list {
      margin-left: 1.52rem;
      margin-right: 0.2rem;
      line-height: 0.28rem;
      list-style: none;
      li {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 0.24rem;
        // max-height: .6rem;
      }
      span {
        color: #919599;
        display: inline-block;
      }
      .font22 {
        font-size: 0.24rem;
        transform: scale(0.92);
      }
      .brand {
        display: contents;
      }
    }
    .sale-tags-list {
      margin-left: 1.75rem;
      padding: 0.12rem 0.22rem 0 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      overflow: hidden;
      max-height: 0.75rem;
      border-bottom: 1px solid #d1d3d3;
      li {
        display: inline-block;
        // width: 88px;
        height: 0.4rem;
        line-height: 0.4rem;
        margin-right: 0.12rem;
        margin-bottom: 0.22rem;
        span {
          display: inherit;
          font-size: 0.24rem;
          line-height: 0.33rem;
          color: #262c32;
          background-color: #f3f5f7;
          border-radius: 0.2rem;
          letter-spacing: 0.72px;
          padding: 0.04rem 0.2rem;
          text-align: center;
        }
      }
    }
  }
}
</style>