/*
 * @Author: liujingfa 
 * @Date: 2020-02-24 11:08:10 
 * @Last Modified by: liujingfa
 * @Last Modified time: 2020-06-18 17:55:36
 */
import http from 'gome-utils-http';
import { Toast } from 'gome-ui-kit';
import cookie from 'gome-utils-cookie';
import base64 from 'gome-utils-base64';
import {
    env,
    system
} from 'gome-utils-env';

// console.log('env:::', env);

let _host = location.host;
let _domain = _host.indexOf("gomeuat") > 0
    ? "gomeuat"
    : _host.indexOf("tslive") > 0
    ? "tslive"
    : "gome";

let imPlatformDomain = '', imPlatformGroupDomain = '', guideDomain = '', loginDomain = '', loginReturnUrl = '';
if (_domain === 'gomeuat' || _domain === 'tslive') {
    // "预生产"环境 im-platform 服务器地址
    imPlatformDomain = "http://api-imrh.pre.gomeplus.com";
    // "预生产"环境 im-platform-group 服务器地址
    imPlatformGroupDomain = "http://api-imrh.pre.gomeplus.com";
    // UAT导购员列表
    guideDomain = `http://mobile.${_domain}.com.cn`;
    // 登录Domain
    loginDomain = `http://login.m.${_domain}.com.cn`;
    // 为登录回跳url
    loginReturnUrl = `http://chat.m.${_domain}.com.cn/message.html`;
} else if(_domain === 'gome') {
    // "生产"环境 im-platform 服务器地址
    imPlatformDomain = "https://im-platform.gome.com.cn";
    // "生产"环境 im-platform-group 服务器地址
    imPlatformGroupDomain = "https://api-im-group.gome.com.cn";
    // UAT导购员列表
    guideDomain = `https://mobile.${_domain}.com.cn`;
    // 登录Domain
    loginDomain = `https://login.m.${_domain}.com.cn`;
    // 为登录回跳url
    loginReturnUrl = `http://chat.m.${_domain}.com.cn/message.html`;
}

const state = {
    appId: _domain === "gome" ? "gomeplus_pro" : "gomeplus_pre",
    groupId: "E4HA00G4H86903_100049583310_3000",
    groupIdsArr: [],
    imUserId: 100051430112, // 会员昵称：该喝下午茶了
    // imUserId: 10211865,
    randomStr32: "Pc0jlCMLaagxhnu8K20iA4wdMPka8HmaHjTV3svtxH8b%2FFkiiJTFMNX2x7%2Fx4Qy53M0YNglEaR639zhAuCPLsX%2FzHEoq0rA3Z5mMtqE%2F%2FNeHw0pSsOTtgYvTeSE3CH%2BZ82a5478e0c918fa23183a4e74bf8bd08",
    customersMsgList: [],
    singleCustomerInfo: {},
    allCustomersInfo: {},
    sellersRecomList: [],
    // 客服消息接口请求标识
    customersLoadFlag: 1, // -1 失败 0 无数据 1有数据
    // 客服信息接口请求标识
    customersInfoLoadFlag: 1, // -1 失败 0 无数据 1有数据
    // 导购员接口请求标识
    sellersLoadFlag: 1, // -1 失败 0 无数据 1有数据
}

const mutations = {
    SET_RANDOM_STR32: (state, payload) => {
        state.randomStr32 = payload;
    },
    SET_CUSTOMERS_MSG_LIST: (state, payload) => {
        state.customersMsgList = payload;
    },
    SET_SINGLE_CUSTOMERS_INFO: (state, payload) => {
        state.singleCustomerInfo = payload;
    },
    SET_ALL_CUSTOMERS_INFO: (state, payload) => {
        state.allCustomersInfo = payload;
    },
    SET_SELLERS_RECOM_LIST: (state, payload) => {
        state.sellersRecomList = payload;
    },
    SET_GROUP_IDS: (state, payload) => {
        state.groupIdsArr = payload;
    },
    SET_CUSTOMERS_LOAD_FLAG: (state, payload) => {
        state.customersLoadFlag = payload;
    },
    SET_CUSTOMERS_INFO_LOAD_FLAG: (state, payload) => {
        state.customersInfoLoadFlag = payload;
    },
    SET_SELLERS_LOAD_FLAG: (state, payload) => {
        state.sellersLoadFlag = payload;
    }
}

const actions = {
    // 随机生成32位字符，作为接口入参scn的值
    getRandomStr32({ commit }) {
        const charArr = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
        let randomStr = '';
        for (let i = 0; i < 32; i++) {
            let index = Math.floor(Math.random() * 62);
            randomStr += charArr[index];
        }
        commit('SET_RANDOM_STR32', randomStr);
    },
    /**
     * 1、entryType为shopping时的参数：
     * extra: "{"entryType":"shopping","dispatchType":"3","source":1,"storeId":"A007","brandcode":"","orgi":"E4HA0074H86913","companyId":"gome","messageType":322,"skill":"","nexttype":0,"nexthelp":0,"staffId":"10278891","categoryid":""}"
     */
    async getCustomersMsgList({ commit, state }, payload) {
        let params = {
            appId: state.appId,
            // scn: state.randomStr32,
            imUserId: cookie.parse()['SSO_USER_ID'] || chat_userid,
            scn: token,
            osType: 30,
            version: 1
        }
        await http({
            url: `${imPlatformDomain}/im-platform/appletCustomerMsg/jsListAppletCustomers.json`,
            type: 'get',
            simpleCors: 'Y',
            data: params,
        }).then(res => {
            if (res.extra && JSON.parse(res.extra).code == 0) {
                commit('SET_CUSTOMERS_MSG_LIST', res);
                const msgList = res.custGroup ? res.custGroup : [];
                const arr = [];
                msgList.map((item, index) => {
                    arr.push(item.groupId)
                })
                commit('SET_GROUP_IDS', arr);
            } else if (res.extra && JSON.parse(res.extra).code == 1) {
                commit('SET_CUSTOMERS_LOAD_FLAG', 0);
                // 没有离线会话
                Toast(JSON.parse(res.extra).msg);
            } else if (res.extra && JSON.parse(res.extra).code == -2) {
                // scn失效，跳登录
                Toast(JSON.parse(res.extra).msg);
                if(document.domain === 'm.gome.com.cn') {
                    window.location.href = `${loginDomain}/login.html?return_url=aHR0cHM6Ly9jaGF0Lm0uZ29tZS5jb20uY24vbWVzc2FnZS5odG1s`;
                } else if(document.domain === 'm.gomeuat.com.cn') {
                    window.location.href = `${loginDomain}/login.html?return_url=aHR0cDovL2NoYXQubS5nb21ldWF0LmNvbS5jbi9tZXNzYWdlLmh0bWw=`;
                } else {
                    window.location.href = loginDomain + '/login.html?return_url=';
                }
            }
        }).catch( e => {
            Toast('加载数据出异常了，请重试下~');
            // 加载异常标识
            commit('SET_CUSTOMERS_LOAD_FLAG', -1);
            console.log(e.name, e.message)
        })
    },
    /**
     * 批量获取客服信息
     * @param {*} imUserId //用户ID，可以为空，若为空，服务端去cookie中的信息
     * @param {*} scn //SCN校验值，可以为空，若为空，服务端去cookie中的信息
     */
    async batchGetCustomerInfos({ commit, state }, payload) {
        let params = {
            traceId: Number(String(new Date().getTime()).substring(2)),
            appId: state.appId,
            groupIds: state.groupIdsArr,
            clientId: 30,
            // scn: state.randomStr32,
            imUserId: cookie.parse()['SSO_USER_ID'] || chat_userid,
            scn: token
        }
        await http({
            url: `${imPlatformGroupDomain}/im-platform-group/customer/jsGetCustomerInfos.json`,
            type: 'get',
            simpleCors: 'Y',
            data: params,
        }).then(res => {
            if(res.code == 0) {
                const { singleChats } = res.data;
                commit('SET_ALL_CUSTOMERS_INFO', res);
                if(singleChats.length === 0) { // 没数据标识
                    commit('SET_CUSTOMERS_INFO_LOAD_FLAG', 0);
                }
            } else { // 没有离线回话时groupIds为空
                if(JSON.stringify(res.data) === '{}') {
                    // console.log('res.message:::', res.message);
                    commit('SET_CUSTOMERS_INFO_LOAD_FLAG', 0);   
                }
            }
        }).catch(e => {
            Toast('加载数据出异常了，请重试下~');
            // 加载异常标识
            commit('SET_CUSTOMERS_INFO_LOAD_FLAG', -1);
            console.log(e.name, e.message)
        })
    },
    async getSellersList({ commit }, payload) {
        const params = {};
        const _stid = cookie.parse()['stid'];
        const _gtid = cookie.parse()['gtid'];
        if (_stid) { // 门店编码，优先根据门店获取导购员
            params.categoryId = '';
            params.stId = _stid;
            // params.stId = 'A007';
            params.townId = '';
            params.filter = 1; // 1只展示有效，0展示全部
        } else { // 当门店ID为空时，可通过四级区域ID获取门店
            params.categoryId = '';
            params.stId = '';
            params.townId = _gtid;
            params.filter = 1; // 1只展示有效，0展示全部
        }
        
        await http({
            url: `${guideDomain}/wap/product/mall/sellers.jsp`,
            type: 'get',
            cors: 'Y',
            data: {
                body: JSON.stringify(params)
            },
        }).then(res => {
            if (res.isSuccess === 'Y') {
                commit('SET_SELLERS_RECOM_LIST', res.dataInfo);
                if (res.dataInfo.length === 0 || typeof res.dataInfo === 'undefined') { // 没数据标识
                    commit('SET_SELLERS_LOAD_FLAG', 0);
                }
            } else {
                 // 加载异常标识
                commit('SET_SELLERS_LOAD_FLAG', -1);
            }
        }).catch(e => {
            console.log(e.name, e.message)
            // 加载异常标识
            commit('SET_SELLERS_LOAD_FLAG', -1);
        })
    },
    setCustomersLoadFlag({ commit, state }, payload) {
        commit('SET_CUSTOMERS_LOAD_FLAG', payload);
    },
    setSellersLoadFlag({ commit, state }, payload) {
        commit('SET_SELLERS_LOAD_FLAG', payload);
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
