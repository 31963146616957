/*
 * @Author: liujingfa 
 * @Date: 2020-02-24 10:06:55
 * @Last Modified by: yinhongwei
 * @Last Modified time: 2022-5-11 15:19:21
 */

<template>
  <section
    class="gui-history-chat-list-wrapper"
    :style="{backgroundColor: customersMsgList.length>0 ? '#fff' : 'transparent', marginBottom: customersLoadFlag !== 1 ? '0' : '.2rem'}"
  >
    <div
      class="chat-list-container default-height"
      :style="{height: isLoadMore ? 'auto' : customersMsgList.length <= 5 ? 'auto' : '6.92rem'}"
      v-if="customersMsgList.length > 0 && customersLoadFlag === 1 && customersInfoLoadFlag === 1"
    >
      <a
        class="chat-list-item"
        v-for="(item, index) in customersMsgList"
        :key="index"
        :href="item.chatJumpUrl"
      >
        <div class="title-wrap">
          <img
            :src="item.iconUrl"
            onerror="this.src='data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='"
          >
          <span class="flag" v-if="item.salerGradeName">{{ item.salerGradeName }}</span>
        </div>
        <ul class="msg-info border-bottom-1px">
          <li>
            <p class="left">
              <span
                :class="['tip', {'online-status': item.onlineStatus === 1, 'offline-status': item.onlineStatus === 2}]"
                v-if="item.onlineStatus !== 0"
              ></span>
              <span class="nickname">{{ item.accountName }}</span>
            </p>
            <p class="right">{{ item.datetime }}</p>
          </li>
          <li>
            <p class="msg">{{ item.msg }}</p>
          </li>
        </ul>
      </a>
    </div>
    <div class="tip-block no-message" v-else-if="showNoMsg">
      <span>暂无客服聊天信息哟～</span>
    </div>
    <div class="tip-block load-error" v-else-if="showErrorMsg">
      <p>客服聊天信息加载失败了哟～</p>
      <div class="btn-reload" @click="handleReloadData">
        <span>重新加载</span>
      </div>
    </div>
    <div
      class="see-more"
      @click="handleLoadMore"
      v-if="customersLoadFlag === 1 && customersMsgList.length>5"
    >
      <span :class="['icon-arrow', {'expand': isLoadMore}]"></span>
    </div>
  </section>
</template>

<script>
import cookie from "gome-utils-cookie";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("chatList");

export default {
  name: "HistoryMessage",
  props: {
    msgList: {
      type: Object,
      default: () => {}
    },
    customersInfo: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState({
      appId: state => state.appId,
      customersLoadFlag: state => state.customersLoadFlag,
      customersInfoLoadFlag: state => state.customersInfoLoadFlag
    }),
    loadFailedTip() {
      let str = "";
      if (this.customersLoadFlag === -1 || this.customersInfoLoadFlag === -1) {
        str = "加载失败了哟～";
      } else if (!this.customersLoadFlag || !this.customersInfoLoadFlag) {
        str = "暂无客服聊天信息哟~";
      }
      return str;
    },
    showNoMsg() {
      const flag =
        (this.customersLoadFlag === 0 && this.customersInfoLoadFlag !== -1) ||
        (this.customersInfoLoadFlag === 0 && this.customersLoadFlag !== -1);
      return flag;
    },
    showErrorMsg() {
      const flag =
        this.customersLoadFlag === -1 || this.customersInfoLoadFlag === -1;
      return flag;
    },
    customersMsgList() {
      const _this = this;
      const customersInfo =
        this.customersInfo.data && this.customersInfo.data.singleChats
          ? this.customersInfo.data.singleChats
          : [];
      const msgList = this.msgList.custGroup ? this.msgList.custGroup : [];
      const _host = location.host;
      const _Domain =
        _host.indexOf("gomeuat") > 0
          ? "gomeuat"
          : _host.indexOf("tslive") > 0
          ? "tslive"
          : "gome";
      // console.log('customersInfo:::', customersInfo);
      // console.log('msgList_before:::', msgList);
      if (customersInfo.length > 0 && msgList.length > 0) {
        msgList.map((m, i) => {
          m.sendTime = m.lastCustMsg.sendTime;
          m.senderName = m.lastCustMsg.senderName || "";
          customersInfo.map((c, k) => {
            if (m.channelId === c.accountId) {
              m.accountId = c.accountId || "";
              m.storeid = c.storeid || "";
              m.staffid = c.staffid || "";
              m.accountName = c.accountName || "";
              m.salerGradeName = c.salerGradeName || "";
              m.iconUrl = c.iconUrl;
              m.status = c.status;
            }
          });
        });
      }
      // 按消息发送时间倒序排序
      msgList.sort(this.compareFunc("sendTime"));
      let arr = [];
      msgList.map((item, index) => {
        let dateStr =
          new Date(item.sendTime).getMonth() +
          1 +
          "月" +
          new Date(item.sendTime).getDate() +
          "日";
        const extraInfo = JSON.parse(item.lastCustMsg.extra);
        let chatUrl = `//chat.m.${_Domain}.com.cn/chat.html`;
        let chatJumpUrl = "";
        // console.log('extraInfo.extPara:::', extraInfo.extPara); // 有为undefined的情况
        // console.log('extraInfo.storeId:::', extraInfo.storeId); // 有为undefined的情况
        if (extraInfo.entryType === "product") {
          // 商品入口
          let _skuId =
            extraInfo.extPara && extraInfo.extPara.skuId
              ? extraInfo.extPara.skuId
              : "";
          let _imageUrl =
            extraInfo.extPara && extraInfo.extPara.imageUrl
              ? extraInfo.extPara.imageUrl
              : "";
          let _title =
            extraInfo.extPara && extraInfo.extPara.title
              ? extraInfo.extPara.title
              : "";
          let _schemeUrl =
            extraInfo.extPara && extraInfo.extPara.schemeUrl
              ? extraInfo.extPara.schemeUrl
              : "";
          let _content =
            extraInfo.extPara && extraInfo.extPara.content
              ? extraInfo.extPara.content
              : "";
          chatJumpUrl = `${chatUrl}?entry=composite&orgi=${extraInfo.orgi ||
            ""}&orgitype=4&appid=${_this.appId}&shopId=${item.storeid ||
            extraInfo.storeId ||
            ""}&staffId=${item.staffid || extraInfo.staffId || ""}&shopName=${
            item.senderName
          }&categoryid=${""}&brandcode=${""}&skuno=${_skuId}&ctx=${cookie.parse()[
            "ctx"
          ] || ""}&shareuid=${cookie.parse()["share_userId"] ||
            ""}&skuId=${_skuId}&imageUrl=${_imageUrl}&name=${_title}&schemeUrl=${_schemeUrl}&goodsPrice=${_content}`;
        } else if (extraInfo.entryType === "shopping") {
          // 导购员入口
          chatJumpUrl = `${chatUrl}?entry=composite&orgi=${extraInfo.orgi ||
            ""}&shopId=${item.storeid ||
            extraInfo.storeId ||
            ""}&staffId=${item.staffid || extraInfo.staffId || ""}`;
        } else {
          // 综合入口
          chatJumpUrl = `${chatUrl}?entry=composite&orgi=${extraInfo.orgi ||
            ""}&shopId=${item.storeid ||
            extraInfo.storeId ||
            ""}&staffId=${item.staffid ||
            extraInfo.staffId ||
            ""}&source=${extraInfo.source ||
            ""}&messageType=${extraInfo.messageType || ""}`;
        }
        arr.push({
          accountId: item.accountId,
          accountName: item.accountName,
          salerGradeName: item.salerGradeName,
          senderName: item.senderName,
          iconUrl:
            item.iconUrl || "//gfs11.gomein.net.cn/T1GRYTByJT1R4cSCrK.png",
          datetime: dateStr,
          chatJumpUrl: chatJumpUrl,
          orgi: extraInfo.orgi ? extraInfo.orgi : "",
          entryType: extraInfo.entryType ? extraInfo.entryType : "",
          companyId: extraInfo.companyId ? extraInfo.companyId : "",
          dispatchType: extraInfo.dispatchType ? extraInfo.dispatchType : "",
          messageType: extraInfo.messageType ? extraInfo.messageType : "",
          nexttype: extraInfo.nexttype ? extraInfo.nexttype : "",
          source: extraInfo.source ? extraInfo.source : "",
          storeId: extraInfo.storeId ? extraInfo.storeId : "",
          staffId: extraInfo.staffId ? extraInfo.staffId : "",
          msg: item.lastCustMsg.msgBody,
          onlineStatus: item.status // 1在线 2离线 0用于门店
        });
      });
      // 列表最多展示30条数据
      arr = arr.length > 30 ? arr.slice(0, 30) : arr;
      return arr;
    }
  },
  data() {
    return {
      isLoadMore: false
    };
  },
  methods: {
    handleLoadMore() {
      this.isLoadMore = !this.isLoadMore;
    },
    compareFunc(propName) {
      return function(obj1, obj2) {
        if (obj1[propName] > obj2[propName]) return -1;
        else if (obj1[propName] < obj2[propName]) return 1;
        else return 0;
      };
    },
    handleReloadData() {
      this.$emit("handleReload");
    }
  }
};
</script>

<style lang="less" scoped>
.gui-history-chat-list-wrapper {
  background-color: #fff;
  margin-top: 0.88rem;
  margin-bottom: 0.2rem;
  .chat-list-container {
    &.default-height {
      overflow-y: hidden;
    }
  }
  .tip-block {
    height: 0.94rem;
    font-size: 0.26rem;
    color: #919599;
    background: #f3f5f7;
    display: flex;
    align-items: center;
    padding: 0 0.32rem;
    &.no-message {
      line-height: 0.45rem;
      justify-content: center;
    }
    &.load-error {
      justify-content: space-between;
      .btn-reload {
        width: 1.4rem;
        height: 0.48rem;
        line-height: 0.48rem;
        text-align: center;
        color: #5a6066;
        border: 1px solid #cacccf;
        border-radius: 32px;
      }
    }
  }
  .chat-list-item {
    display: flex;
    align-items: center;
    height: 1.36rem;
    .title-wrap {
      width: 0.98rem;
      height: 0.98rem;
      margin: 0.2rem 0.3rem;
      position: relative;
      img {
        width: inherit;
        height: auto;
        border-radius: 0.1rem;
      }
      .flag {
        display: inline-block;
        width: 100%;
        height: 0.26rem;
        line-height: 0.26rem;
        color: #fff;
        font-size: 0.2rem;
        background-color: #e6b365;
        text-align: center;
        margin-top: -0.24rem;
        position: absolute;
        z-index: 1;
        border-radius: 0 0 0.1rem 0.1rem;
      }
    }
    .msg-info {
      width: 100%;
      padding: 0.22rem 0.24rem 0.2rem 0;
      li {
        display: flex;
        justify-content: space-between;
        &:first-child {
          .left {
            display: flex;
            align-items: center;
            span {
              display: inline-block;
            }
            .tip {
              width: 0.16rem;
              height: 0.16rem;
              margin-right: 0.14rem;
              // border: 4px solid rgba(255,255,255,0.80);
              border-radius: 50%;
            }
            .online-status {
              background-color: #09bb07;
            }
            .offline-status {
              background-color: #cacccf;
            }
            .nickname {
              font-family: "PingFangSC-Medium";
              font-size: 0.34rem;
              color: #202020;
              letter-spacing: -0.32px;
              line-height: 0.48rem;
            }
          }
          .right {
            font-size: 0.24rem;
            color: #ccc;
            line-height: 0.48rem;
          }
        }
        &:last-child {
          .msg {
            font-size: 0.26rem;
            color: #9b9b9b;
            line-height: 0.48rem;
            width: 4.6rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          // .icon-mute {
          //     width: 35px;
          //     height: 36px;
          //     background: url('./../images/icon_mute@2x.png') no-repeat center center / 100% content-box content-box;
          // }
        }
      }
    }
  }
  .see-more {
    height: 0.68rem;
    line-height: 0.68rem;
    text-align: center;
    .icon-arrow {
      display: inline-block;
      width: 0.2rem;
      height: 0.11rem;
      background: url("./../images/icon_arrow_down@2x.png") no-repeat center
        center / 100% content-box content-box;
    }
    .expand {
      transform: rotate(180deg);
      transition: transform 0.5s ease-in;
    }
  }
}
</style>