/*
 * @Author: liujingfa 
 * @Date: 2020-02-24 11:07:34 
 * @Last Modified by: liujingfa
 * @Last Modified time: 2020-02-25 12:08:02
 */
import Vue from 'vue';
import Vuex from 'vuex';
import chatList from './modules/chatList';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        chatList
    },
});

export default store;
